<template></template>

<script>
export default {
  name: 'Nuke',
  data: () => {
    return {
      blacklist: ['xdid'],
    };
  },
  mounted() {
    for (let key in localStorage) {
      if (this.blacklist.includes(key)) continue;
      localStorage.removeItem(key);
    }

    location.href = `/login?t=${Date.now()}`;
  },
};
</script>

<style scoped></style>
